import React from 'react';
import { Link } from 'react-router-dom';
import ScrollToTopButton from './ScrollToTop';

const Footer = () => {
  return (
    <footer className='footer container-large'>
      <div className='container-medium'>
        <div className='row'>
          <div className='flex-col'>
            <img src={process.env.PUBLIC_URL + '/images/jsp-logo.svg'} alt='jsp' />
          </div>
          <div className='flex-col'>
            <a href='/home'>
              <p>Home</p>
            </a>
            <Link to='/about'>
              <p>About</p>
            </Link>
            <Link to='/writing'>
              <p>Writing</p>
            </Link>
          </div>
          <div className='flex-col'>
            <a href='https://www.instagram.com/skysprowles/'>
              <p>Instagram</p>
            </a>
            <a href='https://www.facebook.com/schuylersprowlesauthor'>
              <p>Facebook</p>
            </a>
            <p>skysprowles@yahoo.com</p>
          </div>
          <ScrollToTopButton />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
