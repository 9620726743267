import ContactForm from '../components/ContactForm';
import SocialCard from '../components/SocialCard';

function Contact() {
  return (
    <>
      <div className='contact-page-wrapper container-large'>
        <div className='row container-medium'>
          <SocialCard />
        </div>
      </div>
    </>
  );
}

export default Contact;
