import React, { useState, useEffect } from 'react';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

function Popup({ trigger, children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ x: '50%', y: 0 });

  const togglePopup = () => {
    if (!isOpen) {
      updatePopupPosition();
    }
    setIsOpen(!isOpen);
  };

  const updatePopupPosition = () => {
    const scrollY = window.scrollY || window.pageYOffset;
    const viewportHeight = window.innerHeight;
    const yPosition = scrollY + viewportHeight / 2 + 50;
    setPopupPosition({ x: '50%', y: yPosition });
  };

  useGSAP(() => {
    if (isOpen) {
      ScrollTrigger.addEventListener('refresh', updatePopupPosition);
      ScrollTrigger.refresh();
    }
    return () => {
      ScrollTrigger.removeEventListener('refresh', updatePopupPosition);
    };
  }, [isOpen]);

  useEffect(() => {
    const handleResize = () => {
      if (isOpen) {
        updatePopupPosition();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isOpen]);

  return (
    <>
      <div onClick={togglePopup}>{trigger}</div>
      {isOpen && (
        <div className='popup-background' onClick={togglePopup}>
          <div className='popup-content' style={{ top: popupPosition.y, left: popupPosition.x }} onClick={(e) => e.stopPropagation()}>
            {children}
          </div>
        </div>
      )}
    </>
  );
}

export default Popup;
