import React from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { motion, transform } from 'framer-motion';

gsap.registerPlugin(ScrollTrigger);

function TYSHero() {
  const scrollToSection = () => {
    gsap.to(window, {
      scrollTo: { y: '#scroll', offsetY: 50 },
      duration: 1.75,
    });
  };

  return (
    <div className='tys-hero'>
      <div className='tys-hero-inner'>
        <div className='parallax'>
          <img className='parallax-img' id='sky' src={process.env.PUBLIC_URL + '/images/tys-sky.png'} alt='' />
          <img className='parallax-img' id='m' src={process.env.PUBLIC_URL + '/images/tys-mountains.png'} alt='' />
          <div className='parallax-text' id='text'>
            <h1>
              T<span>HE</span> Y<span>OUNG</span> S<span>AMARITAN</span>
            </h1>
          </div>
          <img className='parallax-img' id='boy' src={process.env.PUBLIC_URL + '/images/tys-boy.png'} alt='' />
          <img className='parallax-img' id='rock' src={process.env.PUBLIC_URL + '/images/tys-rock.png'} alt='' />
        </div>
        <div className='scroll-container'>
          <motion.div className='scroll' whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} onClick={scrollToSection}>
            <p>Scroll</p>
            <img src={process.env.PUBLIC_URL + '/images/chevron-down.svg'} alt='' />
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default TYSHero;
