import { useAnimation } from 'framer-motion';
import { useEffect } from 'react';
import { gsap } from 'gsap';

const ScrollToTopButton = () => {
  const controls = useAnimation();

  useEffect(() => {
    // Show button when scroll down 100px
    const toggleVisibility = () => {
      if (window.pageYOffset > 100) {
        controls.start({ opacity: 1, scale: 1 });
      } else {
        controls.start({ opacity: 0, scale: 0.5 });
      }
    };

    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [controls]);

  const scrollToTop = () => {
    gsap.to(window, { duration: 0.5, scrollTo: { y: 0 } });
  };

  return (
    <div className='back-to-top' onClick={scrollToTop} initial={{ opacity: 0, scale: 0.5 }} animate={controls} whileHover={{ scale: 1.1 }}>
      ⬆︎
    </div>
  );
};

export default ScrollToTopButton;
