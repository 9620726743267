import Book1 from '../components/Book1';
import WritingHero from '../components/WritingHero';

function Writing() {
  return (
    <main>
      <>
        <WritingHero />
        <div className='writing-page container-large'>
          <div className='writing-title row'>
            <div className='title flex-col'>
              <h2>Writing and works</h2>
            </div>
            <div className='description flex-col'>
              <p className='title-dcr animate-text'>
                Schuyler's writing style reflects his rich and varied professional background, imbued with a depth of experience from his time in
                media, public affairs, and crisis management. His prose blend a journalistic clarity with great storytelling honed through years in
                broadcasting and speech writing. His beliefs have profoundly influenced his creative process. Drawing from the Gospels, he weaves his
                spiritual curiosity into his narratives, likely creating works that are not just stories, but reflections of personal discovery and
                faith.
              </p>
            </div>
          </div>
          <Book1 />
        </div>
      </>
    </main>
  );
}

export default Writing;
