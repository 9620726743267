import React from 'react';
import '../css/styleguide.css';
import { Link } from 'react-router-dom';

const Book1 = () => {
  return (
    <div className='book-wrapper'>
      <div className='book-inner'>
        <div className='grid-col'>
          <div className='book-text'>
            <h3>The Young Samaritan</h3>
            <p>
              The Young Samaritan is inspired by a single passage from the Gospel of Mark, this coming-of-age saga tells of Joshua, a Samaritan boy,
              who fears his life is in danger and is forced to flee home. In this extraodinary journey of hardship and discovery, Joshua endures a
              harsh and unexpected world as heartbreaking as it is joyful. From an aged mystic woman who delivers a haunting vision, a wild dog that
              curiously stays by his side and a reclusive uncle who hides from the world, Joshua finds uncommon love in a forsaken <span>...</span>
            </p>
            <div className='btn'>
              <a href='/writing/the-young-samaritan'>Learn more</a>
            </div>
          </div>
        </div>
        <div className='grid-col'>
          <div className='book'>
            <a href='/writing/the-young-samaritan'>
              <img src={process.env.PUBLIC_URL + '/images/book1.png'} alt='' />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Book1;
