import React from 'react';

export default function AboutHero() {
  return (
    <div className='writing-hero-background'>
      <div className='hero-content row container-large'>
        <div className='hero-text'>
          <h1>About</h1>
        </div>
        <div className='hero-img'>
          <img src={process.env.PUBLIC_URL + '/images/sky-portrait-2.jpg'} alt='' />
        </div>
      </div>
    </div>
  );
}
