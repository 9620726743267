import { useState, useEffect } from 'react';
import preloadImage from '../hooks/preloadImage';
import AboutHero from '../components/AboutHero';

function About() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // URLs of the images to preload
    const imageUrls = [process.env.PUBLIC_URL + '/images/sky-portrait.jpg'];

    // Preload all images
    const preloadImages = imageUrls.map((url) => preloadImage(url));

    // Wait for all images to load
    Promise.all(preloadImages)
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error in loading resources: ', error);
        setIsLoading(false); // Consider how to handle the error state
      });
  }, []);

  return (
    <main>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <AboutHero />
          <div className='about-page container-large'>
            <div className='about-sky'>
              <div className='title'>
                <h2>About Schuyler</h2>
              </div>
              <div className='about-sky-grid'>
                <div className='portrait'>
                  <img src={process.env.PUBLIC_URL + '/images/sky-portrait.jpg'} alt='Portrait of Schuyler Sprowles' />
                </div>
                <div className='bio'>
                  <p className='animate-text'>
                    Schuyler Sprowles began his career in radio as a country music personality and news reporter for numerous stations, ultimately
                    becoming a television news anchor in Dallas, Texas. In time Schuyler moved to Los Angeles, where he worked as a tv news
                    correspondent. This ultimately led to a high-profile public affairs career as Communications Director for major Los Angeles public
                    agencies, including City Attorney, District Attorney and the Department of Children and Family Services. Following years in crisis
                    management and speech writing, Schuyler turned to publishing, founding a family-oriented magazine that remains in circulation
                    today. A lifelong Christian, Schuyler admits to walking away from his faith at times, only to discover Jesus waiting in the
                    shadows to guide him back where he belongs. His debut novel is inspired by the Gospels.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </main>
  );
}

export default About;
