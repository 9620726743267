import '../css/styleguide.css';
import React from 'react';

function SocialCard() {
  return (
    <div className='social-card'>
      <div className='title'>
        <h3>Follow</h3>
      </div>
      <ul>
        <li>
          <a href='https://www.instagram.com/skysprowles/'>
            <img src={process.env.PUBLIC_URL + '/images/ig.svg'} alt='Instagram logo' />
            <h4>Instagram</h4>
          </a>
        </li>
        <li>
          <a href='https://www.facebook.com/schuyler.sprowles/'>
            <img src={process.env.PUBLIC_URL + '/images/fb.svg'} alt='Facebook logo' />
            <h4>Facebook</h4>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default SocialCard;
