import React from "react";


export default function WritingHero() {
  return (
    <div className="writing-hero-background">
      <div className="hero-content row container-large">
        <div className="hero-text">
          <h1>Writing</h1>
        </div>
        <div className="hero-img">
          <img src={process.env.PUBLIC_URL + "/images/tys-covershot-2.png"} alt="" />
        </div>
      </div>
    </div>
  )
}