import React, { useState } from 'react';
import '../components/Navbar.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false); // This will close the menu
  };

  return (
    <nav className='navbar'>
      <div className='navbar-wrapper container-large'>
        <div className='logo'>
          <a href='/home'>
            <h2>J. Schuyler Sprowles</h2>
          </a>
        </div>
        <div className='hamburger' onClick={toggleMenu}>
          <div className={`bar ${isMenuOpen ? 'white' : ''}`}></div>
          <div className={`bar ${isMenuOpen ? 'white' : ''}`}></div>
          <div className={`bar ${isMenuOpen ? 'white' : ''}`}></div>
        </div>
        <div className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
          <ul>
            <li>
              <a className='navlink' href='/writing/the-young-samaritan' onClick={handleLinkClick}>
                Home
              </a>
            </li>
            <li>
              <Link className='navlink' to='/about' onClick={handleLinkClick}>
                About
              </Link>
            </li>
            <li>
              <Link className='navlink' to='/writing' onClick={handleLinkClick}>
                Writing
              </Link>
            </li>
            <li>
              <div className='underline'></div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
